@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val add_expense_to_group: StringResource by 
      lazy { init_add_expense_to_group() }

  public val add_group_cd: StringResource by 
      lazy { init_add_group_cd() }

  public val add_user_to_group: StringResource by 
      lazy { init_add_user_to_group() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val app_version: StringResource by 
      lazy { init_app_version() }

  public val back: StringResource by 
      lazy { init_back() }

  public val back_btn_cd: StringResource by 
      lazy { init_back_btn_cd() }

  public val change_color_mode: StringResource by 
      lazy { init_change_color_mode() }

  public val change_theme: StringResource by 
      lazy { init_change_theme() }

  public val close_picker: StringResource by 
      lazy { init_close_picker() }

  public val contacts_in_book: StringResource by 
      lazy { init_contacts_in_book() }

  public val contacts_in_wesplit: StringResource by 
      lazy { init_contacts_in_wesplit() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_contact: StringResource by 
      lazy { init_create_new_contact() }

  public val edit_group: StringResource by 
      lazy { init_edit_group() }

  public val empty_group_cd: StringResource by 
      lazy { init_empty_group_cd() }

  public val empty_transaction_description: StringResource by 
      lazy { init_empty_transaction_description() }

  public val empty_transactions_cd: StringResource by 
      lazy { init_empty_transactions_cd() }

  public val grant_permission: StringResource by 
      lazy { init_grant_permission() }

  public val group_detailed_empty_description: StringResource by 
      lazy { init_group_detailed_empty_description() }

  public val group_list_empty_description_authorized: StringResource by 
      lazy { init_group_list_empty_description_authorized() }

  public val group_list_empty_description_unregistered: StringResource by 
      lazy { init_group_list_empty_description_unregistered() }

  public val group_list_title: StringResource by 
      lazy { init_group_list_title() }

  public val group_name: StringResource by 
      lazy { init_group_name() }

  public val groups: StringResource by 
      lazy { init_groups() }

  public val loading: StringResource by 
      lazy { init_loading() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_button_cd: StringResource by 
      lazy { init_login_button_cd() }

  public val login_to_create_descr: StringResource by 
      lazy { init_login_to_create_descr() }

  public val login_with_apple_cd: StringResource by 
      lazy { init_login_with_apple_cd() }

  public val login_with_google_cd: StringResource by 
      lazy { init_login_with_google_cd() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val new: StringResource by 
      lazy { init_new() }

  public val new_expense: StringResource by 
      lazy { init_new_expense() }

  public val new_group: StringResource by 
      lazy { init_new_group() }

  public val no_contact_found: StringResource by 
      lazy { init_no_contact_found() }

  public val no_image_group_cd: StringResource by 
      lazy { init_no_image_group_cd() }

  public val non_distr_cd: StringResource by 
      lazy { init_non_distr_cd() }

  public val or: StringResource by 
      lazy { init_or() }

  public val profile: StringResource by 
      lazy { init_profile() }

  public val profile_under_construction: StringResource by 
      lazy { init_profile_under_construction() }

  public val retry: StringResource by 
      lazy { init_retry() }

  public val save: StringResource by 
      lazy { init_save() }

  public val search_contact: StringResource by 
      lazy { init_search_contact() }

  public val select_payer_cd: StringResource by 
      lazy { init_select_payer_cd() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val share_group: StringResource by 
      lazy { init_share_group() }

  public val share_link_copied: StringResource by 
      lazy { init_share_link_copied() }

  public val start_type_creat_contact: StringResource by 
      lazy { init_start_type_creat_contact() }

  public val submit_feedback_cd: StringResource by 
      lazy { init_submit_feedback_cd() }

  public val user_already_in_group: StringResource by 
      lazy { init_user_already_in_group() }

  public val you: StringResource by 
      lazy { init_you() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("add_expense_to_group", CommonMainString0.add_expense_to_group)
  map.put("add_group_cd", CommonMainString0.add_group_cd)
  map.put("add_user_to_group", CommonMainString0.add_user_to_group)
  map.put("app_name", CommonMainString0.app_name)
  map.put("app_version", CommonMainString0.app_version)
  map.put("back", CommonMainString0.back)
  map.put("back_btn_cd", CommonMainString0.back_btn_cd)
  map.put("change_color_mode", CommonMainString0.change_color_mode)
  map.put("change_theme", CommonMainString0.change_theme)
  map.put("close_picker", CommonMainString0.close_picker)
  map.put("contacts_in_book", CommonMainString0.contacts_in_book)
  map.put("contacts_in_wesplit", CommonMainString0.contacts_in_wesplit)
  map.put("create", CommonMainString0.create)
  map.put("create_new_contact", CommonMainString0.create_new_contact)
  map.put("edit_group", CommonMainString0.edit_group)
  map.put("empty_group_cd", CommonMainString0.empty_group_cd)
  map.put("empty_transaction_description", CommonMainString0.empty_transaction_description)
  map.put("empty_transactions_cd", CommonMainString0.empty_transactions_cd)
  map.put("grant_permission", CommonMainString0.grant_permission)
  map.put("group_detailed_empty_description", CommonMainString0.group_detailed_empty_description)
  map.put("group_list_empty_description_authorized",
      CommonMainString0.group_list_empty_description_authorized)
  map.put("group_list_empty_description_unregistered",
      CommonMainString0.group_list_empty_description_unregistered)
  map.put("group_list_title", CommonMainString0.group_list_title)
  map.put("group_name", CommonMainString0.group_name)
  map.put("groups", CommonMainString0.groups)
  map.put("loading", CommonMainString0.loading)
  map.put("login", CommonMainString0.login)
  map.put("login_button_cd", CommonMainString0.login_button_cd)
  map.put("login_to_create_descr", CommonMainString0.login_to_create_descr)
  map.put("login_with_apple_cd", CommonMainString0.login_with_apple_cd)
  map.put("login_with_google_cd", CommonMainString0.login_with_google_cd)
  map.put("logout", CommonMainString0.logout)
  map.put("new", CommonMainString0.new)
  map.put("new_expense", CommonMainString0.new_expense)
  map.put("new_group", CommonMainString0.new_group)
  map.put("no_contact_found", CommonMainString0.no_contact_found)
  map.put("no_image_group_cd", CommonMainString0.no_image_group_cd)
  map.put("non_distr_cd", CommonMainString0.non_distr_cd)
  map.put("or", CommonMainString0.or)
  map.put("profile", CommonMainString0.profile)
  map.put("profile_under_construction", CommonMainString0.profile_under_construction)
  map.put("retry", CommonMainString0.retry)
  map.put("save", CommonMainString0.save)
  map.put("search_contact", CommonMainString0.search_contact)
  map.put("select_payer_cd", CommonMainString0.select_payer_cd)
  map.put("settings", CommonMainString0.settings)
  map.put("share_group", CommonMainString0.share_group)
  map.put("share_link_copied", CommonMainString0.share_link_copied)
  map.put("start_type_creat_contact", CommonMainString0.start_type_creat_contact)
  map.put("submit_feedback_cd", CommonMainString0.submit_feedback_cd)
  map.put("user_already_in_group", CommonMainString0.user_already_in_group)
  map.put("you", CommonMainString0.you)
}

internal val Res.string.add_expense_to_group: StringResource
  get() = CommonMainString0.add_expense_to_group

private fun init_add_expense_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_expense_to_group", "add_expense_to_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 10, 76),
    )
)

internal val Res.string.add_group_cd: StringResource
  get() = CommonMainString0.add_group_cd

private fun init_add_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_group_cd", "add_group_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 87, 44),
    )
)

internal val Res.string.add_user_to_group: StringResource
  get() = CommonMainString0.add_user_to_group

private fun init_add_user_to_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_user_to_group", "add_user_to_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 132, 53),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 186, 28),
    )
)

internal val Res.string.app_version: StringResource
  get() = CommonMainString0.app_version

private fun init_app_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_version", "app_version",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 215, 27),
    )
)

internal val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 275, 20),
    )
)

internal val Res.string.back_btn_cd: StringResource
  get() = CommonMainString0.back_btn_cd

private fun init_back_btn_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back_btn_cd", "back_btn_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 243, 31),
    )
)

internal val Res.string.change_color_mode: StringResource
  get() = CommonMainString0.change_color_mode

private fun init_change_color_mode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:change_color_mode", "change_color_mode",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 296, 49),
    )
)

internal val Res.string.change_theme: StringResource
  get() = CommonMainString0.change_theme

private fun init_change_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:change_theme", "change_theme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 346, 36),
    )
)

internal val Res.string.close_picker: StringResource
  get() = CommonMainString0.close_picker

private fun init_close_picker(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close_picker", "close_picker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 383, 40),
    )
)

internal val Res.string.contacts_in_book: StringResource
  get() = CommonMainString0.contacts_in_book

private fun init_contacts_in_book(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_book", "contacts_in_book",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 424, 56),
    )
)

internal val Res.string.contacts_in_wesplit: StringResource
  get() = CommonMainString0.contacts_in_wesplit

private fun init_contacts_in_wesplit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contacts_in_wesplit", "contacts_in_wesplit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 481, 75),
    )
)

internal val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 624, 22),
    )
)

internal val Res.string.create_new_contact: StringResource
  get() = CommonMainString0.create_new_contact

private fun init_create_new_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_new_contact", "create_new_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 557, 66),
    )
)

internal val Res.string.edit_group: StringResource
  get() = CommonMainString0.edit_group

private fun init_edit_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit_group", "edit_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 647, 34),
    )
)

internal val Res.string.empty_group_cd: StringResource
  get() = CommonMainString0.empty_group_cd

private fun init_empty_group_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:empty_group_cd", "empty_group_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 682, 46),
    )
)

internal val Res.string.empty_transaction_description: StringResource
  get() = CommonMainString0.empty_transaction_description

private fun init_empty_transaction_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transaction_description", "empty_transaction_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 729,
    125),
    )
)

internal val Res.string.empty_transactions_cd: StringResource
  get() = CommonMainString0.empty_transactions_cd

private fun init_empty_transactions_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:empty_transactions_cd", "empty_transactions_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 855, 57),
    )
)

internal val Res.string.grant_permission: StringResource
  get() = CommonMainString0.grant_permission

private fun init_grant_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:grant_permission", "grant_permission",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 913, 48),
    )
)

internal val Res.string.group_detailed_empty_description: StringResource
  get() = CommonMainString0.group_detailed_empty_description

private fun init_group_detailed_empty_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_detailed_empty_description", "group_detailed_empty_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 962,
    128),
    )
)

internal val Res.string.group_list_empty_description_authorized: StringResource
  get() = CommonMainString0.group_list_empty_description_authorized

private fun init_group_list_empty_description_authorized(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_authorized", "group_list_empty_description_authorized",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1091,
    103),
    )
)

internal val Res.string.group_list_empty_description_unregistered: StringResource
  get() = CommonMainString0.group_list_empty_description_unregistered

private fun init_group_list_empty_description_unregistered(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_empty_description_unregistered", "group_list_empty_description_unregistered",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1195,
    81),
    )
)

internal val Res.string.group_list_title: StringResource
  get() = CommonMainString0.group_list_title

private fun init_group_list_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:group_list_title", "group_list_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1277,
    32),
    )
)

internal val Res.string.group_name: StringResource
  get() = CommonMainString0.group_name

private fun init_group_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:group_name", "group_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1310,
    34),
    )
)

internal val Res.string.groups: StringResource
  get() = CommonMainString0.groups

private fun init_groups(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:groups", "groups",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1345,
    22),
    )
)

internal val Res.string.loading: StringResource
  get() = CommonMainString0.loading

private fun init_loading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loading", "loading",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1368,
    27),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1647,
    21),
    )
)

internal val Res.string.login_button_cd: StringResource
  get() = CommonMainString0.login_button_cd

private fun init_login_button_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_button_cd", "login_button_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1396,
    31),
    )
)

internal val Res.string.login_to_create_descr: StringResource
  get() = CommonMainString0.login_to_create_descr

private fun init_login_to_create_descr(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_to_create_descr", "login_to_create_descr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1428,
    113),
    )
)

internal val Res.string.login_with_apple_cd: StringResource
  get() = CommonMainString0.login_with_apple_cd

private fun init_login_with_apple_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_apple_cd", "login_with_apple_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1542,
    51),
    )
)

internal val Res.string.login_with_google_cd: StringResource
  get() = CommonMainString0.login_with_google_cd

private fun init_login_with_google_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_with_google_cd", "login_with_google_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1594,
    52),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1669,
    22),
    )
)

internal val Res.string.new: StringResource
  get() = CommonMainString0.new

private fun init_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new", "new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1758,
    15),
    )
)

internal val Res.string.new_expense: StringResource
  get() = CommonMainString0.new_expense

private fun init_new_expense(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_expense", "new_expense",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1692,
    35),
    )
)

internal val Res.string.new_group: StringResource
  get() = CommonMainString0.new_group

private fun init_new_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_group", "new_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1728,
    29),
    )
)

internal val Res.string.no_contact_found: StringResource
  get() = CommonMainString0.no_contact_found

private fun init_no_contact_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_contact_found", "no_contact_found",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1774,
    64),
    )
)

internal val Res.string.no_image_group_cd: StringResource
  get() = CommonMainString0.no_image_group_cd

private fun init_no_image_group_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:no_image_group_cd", "no_image_group_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1839,
    53),
    )
)

internal val Res.string.non_distr_cd: StringResource
  get() = CommonMainString0.non_distr_cd

private fun init_non_distr_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:non_distr_cd", "non_distr_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1893,
    52),
    )
)

internal val Res.string.or: StringResource
  get() = CommonMainString0.or

private fun init_or(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:or", "or",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1946,
    14),
    )
)

internal val Res.string.profile: StringResource
  get() = CommonMainString0.profile

private fun init_profile(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:profile", "profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2032,
    27),
    )
)

internal val Res.string.profile_under_construction: StringResource
  get() = CommonMainString0.profile_under_construction

private fun init_profile_under_construction(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_under_construction", "profile_under_construction",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 1961,
    70),
    )
)

internal val Res.string.retry: StringResource
  get() = CommonMainString0.retry

private fun init_retry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:retry", "retry",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2060,
    21),
    )
)

internal val Res.string.save: StringResource
  get() = CommonMainString0.save

private fun init_save(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save", "save",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2082,
    20),
    )
)

internal val Res.string.search_contact: StringResource
  get() = CommonMainString0.search_contact

private fun init_search_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_contact", "search_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2103,
    46),
    )
)

internal val Res.string.select_payer_cd: StringResource
  get() = CommonMainString0.select_payer_cd

private fun init_select_payer_cd(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_payer_cd", "select_payer_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2150,
    55),
    )
)

internal val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2206,
    28),
    )
)

internal val Res.string.share_group: StringResource
  get() = CommonMainString0.share_group

private fun init_share_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:share_group", "share_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2235,
    43),
    )
)

internal val Res.string.share_link_copied: StringResource
  get() = CommonMainString0.share_link_copied

private fun init_share_link_copied(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:share_link_copied", "share_link_copied",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2279,
    53),
    )
)

internal val Res.string.start_type_creat_contact: StringResource
  get() = CommonMainString0.start_type_creat_contact

private fun init_start_type_creat_contact(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_type_creat_contact", "start_type_creat_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2333,
    88),
    )
)

internal val Res.string.submit_feedback_cd: StringResource
  get() = CommonMainString0.submit_feedback_cd

private fun init_submit_feedback_cd(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:submit_feedback_cd", "submit_feedback_cd",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2422,
    46),
    )
)

internal val Res.string.user_already_in_group: StringResource
  get() = CommonMainString0.user_already_in_group

private fun init_user_already_in_group(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:user_already_in_group", "user_already_in_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2469,
    81),
    )
)

internal val Res.string.you: StringResource
  get() = CommonMainString0.you

private fun init_you(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:you", "you",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/values/strings.commonMain.cvr", 2551,
    15),
    )
)
