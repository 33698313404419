package app.wesplit.theme

import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable

@Composable
fun MaterialTypography() =
    Typography().run {
        // TODO: lexend/publicsans dont supported cyrilic
        Typography()
    }
