package org.koin.ksp.generated

import org.koin.core.module.Module
import org.koin.dsl.*


public val app_wesplit_data_firebase_FirebaseDataModule : Module get() = module {
	single() { app.wesplit.data.firebase.AccountFirebaseRepository(analytics=get(),coroutineDispatcher=get(),loginDelegate=get()) } bind(app.wesplit.domain.model.account.AccountRepository::class)
	single() { app.wesplit.data.firebase.BalanceFirebaseRepository(expenseRepository=get(),groupRepository=get(),calculateBalanceUsecase=get()) } bind(app.wesplit.domain.model.group.balance.BalanceRepository::class)
	single() { app.wesplit.data.firebase.ExpenseFirebaseRepository(analyticsManager=get()) } bind(app.wesplit.domain.model.expense.ExpenseRepository::class)
	single() { app.wesplit.data.firebase.GroupFirebaseRepository(analyticsManager=get()) } bind(app.wesplit.domain.model.group.GroupRepository::class)
}
public val app.wesplit.data.firebase.FirebaseDataModule.module : org.koin.core.module.Module get() = app_wesplit_data_firebase_FirebaseDataModule