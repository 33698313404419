@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package split.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val btn_google_dark: DrawableResource by 
      lazy { init_btn_google_dark() }

  public val btn_google_light: DrawableResource by 
      lazy { init_btn_google_light() }

  public val btn_google_neutral: DrawableResource by 
      lazy { init_btn_google_neutral() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val ic_android: DrawableResource by 
      lazy { init_ic_android() }

  public val ic_dark: DrawableResource by 
      lazy { init_ic_dark() }

  public val ic_down: DrawableResource by 
      lazy { init_ic_down() }

  public val ic_feedback: DrawableResource by 
      lazy { init_ic_feedback() }

  public val ic_flag: DrawableResource by 
      lazy { init_ic_flag() }

  public val ic_group: DrawableResource by 
      lazy { init_ic_group() }

  public val ic_light: DrawableResource by 
      lazy { init_ic_light() }

  public val ic_profile: DrawableResource by 
      lazy { init_ic_profile() }

  public val ic_split_money: DrawableResource by 
      lazy { init_ic_split_money() }

  public val ic_system: DrawableResource by 
      lazy { init_ic_system() }

  public val ic_user: DrawableResource by 
      lazy { init_ic_user() }

  public val ic_user_add: DrawableResource by 
      lazy { init_ic_user_add() }

  public val ic_web: DrawableResource by 
      lazy { init_ic_web() }

  public val img_add_data: DrawableResource by 
      lazy { init_img_add_data() }

  public val img_construct: DrawableResource by 
      lazy { init_img_construct() }

  public val img_empty_group: DrawableResource by 
      lazy { init_img_empty_group() }

  public val img_login: DrawableResource by 
      lazy { init_img_login() }

  public val img_logo_black: DrawableResource by 
      lazy { init_img_logo_black() }

  public val img_logo_color: DrawableResource by 
      lazy { init_img_logo_color() }

  public val img_logo_white: DrawableResource by 
      lazy { init_img_logo_white() }

  public val img_search_empty: DrawableResource by 
      lazy { init_img_search_empty() }
}

internal val Res.drawable.btn_google_dark: DrawableResource
  get() = CommonMainDrawable0.btn_google_dark

private fun init_btn_google_dark(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_dark.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_light: DrawableResource
  get() = CommonMainDrawable0.btn_google_light

private fun init_btn_google_light(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_light.xml", -1, -1),
    )
)

internal val Res.drawable.btn_google_neutral: DrawableResource
  get() = CommonMainDrawable0.btn_google_neutral

private fun init_btn_google_neutral(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:btn_google_neutral",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/btn_google_neutral.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.ic_android: DrawableResource
  get() = CommonMainDrawable0.ic_android

private fun init_ic_android(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_android",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_android.xml", -1, -1),
    )
)

internal val Res.drawable.ic_dark: DrawableResource
  get() = CommonMainDrawable0.ic_dark

private fun init_ic_dark(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_dark",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_dark.xml", -1, -1),
    )
)

internal val Res.drawable.ic_down: DrawableResource
  get() = CommonMainDrawable0.ic_down

private fun init_ic_down(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_down.xml", -1, -1),
    )
)

internal val Res.drawable.ic_feedback: DrawableResource
  get() = CommonMainDrawable0.ic_feedback

private fun init_ic_feedback(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_feedback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_feedback.xml", -1, -1),
    )
)

internal val Res.drawable.ic_flag: DrawableResource
  get() = CommonMainDrawable0.ic_flag

private fun init_ic_flag(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_flag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_flag.xml", -1, -1),
    )
)

internal val Res.drawable.ic_group: DrawableResource
  get() = CommonMainDrawable0.ic_group

private fun init_ic_group(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_group.xml", -1, -1),
    )
)

internal val Res.drawable.ic_light: DrawableResource
  get() = CommonMainDrawable0.ic_light

private fun init_ic_light(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_light.xml", -1, -1),
    )
)

internal val Res.drawable.ic_profile: DrawableResource
  get() = CommonMainDrawable0.ic_profile

private fun init_ic_profile(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_profile.xml", -1, -1),
    )
)

internal val Res.drawable.ic_split_money: DrawableResource
  get() = CommonMainDrawable0.ic_split_money

private fun init_ic_split_money(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_split_money",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_split_money.png", -1, -1),
    )
)

internal val Res.drawable.ic_system: DrawableResource
  get() = CommonMainDrawable0.ic_system

private fun init_ic_system(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_system",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_system.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user: DrawableResource
  get() = CommonMainDrawable0.ic_user

private fun init_ic_user(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user.xml", -1, -1),
    )
)

internal val Res.drawable.ic_user_add: DrawableResource
  get() = CommonMainDrawable0.ic_user_add

private fun init_ic_user_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_user_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_user_add.xml", -1, -1),
    )
)

internal val Res.drawable.ic_web: DrawableResource
  get() = CommonMainDrawable0.ic_web

private fun init_ic_web(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_web",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/ic_web.xml", -1, -1),
    )
)

internal val Res.drawable.img_add_data: DrawableResource
  get() = CommonMainDrawable0.img_add_data

private fun init_img_add_data(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_add_data",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_add_data.xml", -1, -1),
    )
)

internal val Res.drawable.img_construct: DrawableResource
  get() = CommonMainDrawable0.img_construct

private fun init_img_construct(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_construct",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_construct.xml", -1, -1),
    )
)

internal val Res.drawable.img_empty_group: DrawableResource
  get() = CommonMainDrawable0.img_empty_group

private fun init_img_empty_group(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_empty_group",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_empty_group.xml", -1, -1),
    )
)

internal val Res.drawable.img_login: DrawableResource
  get() = CommonMainDrawable0.img_login

private fun init_img_login(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_login",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_login.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_black: DrawableResource
  get() = CommonMainDrawable0.img_logo_black

private fun init_img_logo_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_black.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_color: DrawableResource
  get() = CommonMainDrawable0.img_logo_color

private fun init_img_logo_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_color.xml", -1, -1),
    )
)

internal val Res.drawable.img_logo_white: DrawableResource
  get() = CommonMainDrawable0.img_logo_white

private fun init_img_logo_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_logo_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_logo_white.xml", -1, -1),
    )
)

internal val Res.drawable.img_search_empty: DrawableResource
  get() = CommonMainDrawable0.img_search_empty

private fun init_img_search_empty(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:img_search_empty",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/split.composeapp.generated.resources/drawable/img_search_empty.xml", -1, -1),
    )
)
